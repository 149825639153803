<template>
  <div class="px-7" v-if="log">
    <v-timeline dense>
      <v-timeline-item
        v-for="(item, i) in log.data"
        :key="i"
        color="blue"
        icon="mdi-google-assistant"
        small
        fill-dot
      >
        <v-card class="pa-2">
          <v-row>
            <v-col cols="12" md="12" lg="2">
              <v-card
                flat
                color="blue lighten-5"
                class="d-flex flex-column align-center"
              >
                <v-icon large color="blue">mdi-calendar</v-icon>
                <small class="grey--text">{{
                  $date(item.created_at).format("DD/MM/YYYY")
                }}</small>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="7" class="d-flex align-center">
              <div>
                <p class="ma-0">{{ item.description }}</p>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="3" class="d-flex align-center">
              <div class="d-flex align-center">
                {{ $date(item.created_at).fromNow() }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LOG",
  computed: {
    ...mapState({
      log: (state) => state.log.log,
    }),
  },
  data() {
    return {
      items: [
        {
          color: "red lighten-2",
          icon: "mdi-star",
        },
        {
          color: "purple darken-1",
          icon: "mdi-book-variant",
        },
        {
          color: "green lighten-1",
          icon: "mdi-airballoon",
        },
        {
          color: "indigo",
          icon: "mdi-buffer",
        },
      ],
    };
  },
  mounted() {
    this.getLog();
  },
  methods: {
    getLog() {
      let data = {
        page: 1,
        limit: 100,
      };
      this.$store.dispatch("log/listLog", data).then(() => {
      });
    },
  },
};
</script>

<style>
</style>